import React, { useEffect, useState } from 'react'
import Cta from '~globals/Cta'
import parse from 'html-react-parser'
import { graphql, useStaticQuery } from 'gatsby'
import Container from '~globals/Container'

const NotFoundPage = () => {
  const [lang, setLang] = useState('en')

  useEffect(() => {
    setLang(window.location.pathname.includes('/de/') ? 'de' : 'en')
  }, [])

  const { NotFound, NotFoundDE } = useStaticQuery(graphql`
    query NotFoundQuery {
      NotFound:allPrismic404(filter: {lang: {eq: "en-eu"}}) {
        nodes {
          data {
            cta {
              text
             }
            text_1 {
              html
            }
            text_2 {
              html
            }
            title {
              html
            }
          }
        }
      },
      NotFoundDE:allPrismic404(filter: {lang: {eq: "de-de"}}) {
        nodes {
          data {
            cta {
              text
             }
            text_1 {
              html
            }
            text_2 {
              html
            }
            title {
              html
            }
          }
        }
      }
    }
  `)

  const {
    cta,
    text_1,
    text_2,
    title
  } = lang === 'en' ? NotFound.nodes[0].data : NotFoundDE.nodes[0].data

  return (
    <Container extraClassName="notFound">
      <main data-html>
        <div className="typeHeadlineXXL">{parse(title.html)}</div>
        <div className="typeBodyXl">{parse(text_1.html)}</div>
        <div className="typeBodyXl">{parse(text_2.html)}</div>
        <Cta
          text={cta?.text}
          url={lang === 'en' ? '/' : '/de'}
        />
      </main>
    </Container>
  )
}

export default NotFoundPage
